import styled from "styled-components"
import { Container as GridContainer } from "styled-bootstrap-grid"
import media from "styled-media-query"
import Img from "gatsby-image"

export const Container = styled(GridContainer)`
  padding-left: 0px;
  padding-right: 0px;
`

export const Section = styled.section`
  display: flex;
  flex-direction: column;


  ${media.greaterThan("medium")`
    flex-direction: row-reverse;
    padding-bottom: 0px;
  `}

  background-color: ${props => props.theme.colors.greenLighter};
  padding-bottom: 60px;
  border-radius: 10px;

`

export const Imagem = styled(Img)`
  height: 321px;

  ${media.greaterThan("medium")`
    display: none;
  `}
`

export const ImagemDesktop = styled(Img)`
  width: 534px;
  height: 432px;

  ${media.lessThan("medium")`
    display: none;
  `};
  border-radius: 0px 10px 10px 0px;
`

export const Title = styled.h2`
  margin-left: 15px;
  margin-right: 35px;
  margin-top: 60px;
  font-size: 36px;
  font-weight: 800;
  line-height: 1.28;
  color: ${props => props.theme.colors.greenDarker};

  ${media.lessThan("medium")`
    width: 280px;
  `}
`

export const Description = styled.p`
  margin-top: 12px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.charcoal};
  span {
    margin-left: 5px;
    font-weight: bold;
  }
`

export const Benefits = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
`

export const Benefit = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.greaterThan("medium")`
    width: 180px;
  `}
`

export const Icon = styled.img`
  src: url(${props => props.src});
`

export const Text = styled.h3`
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: ${props => props.theme.colors.charcoal};

  ${media.greaterThan("medium")`
    width: 100px;
  `}
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${media.greaterThan("medium")`
    margin-left: 60px;
    margin-right: 60px;
    width: 650px;
  `};
`
