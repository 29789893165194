import styled from "styled-components"
import { Container as GridContainer } from "styled-bootstrap-grid"
import { Link } from "gatsby"
import media from "styled-media-query"
import { generateMedia } from "styled-media-query"

const customMedia = generateMedia({
  desktop: "78em",
  tablet: "60em",
  mobile: "46em",
})

export const Container = styled(GridContainer)`
  display: flex;
  justify-content: center;
  background-color: ${props => props.theme.colors.greenLighter};
`

export const Section = styled.section`
  padding-top: 60px;
  padding-bottom: 15px;
  padding-left: 13px;
  padding-right: 13px;

  ${media.greaterThan("medium")`
    padding-bottom: 60px;
    width: 1140px;
  `}
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;

  ${customMedia.greaterThan("desktop")`
     flex-direction: row;
      justify-content: space-between;
      align-items: center;
  `};
`

export const Logo = styled.img`
  width: 139px;
  height: 33px;
  src: url(${props => props.src});
`

export const Description = styled.p`
  max-width: 470px;
  margin-right: 30px;
  margin-top: 30px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.colors.charcoal};
  text-align: justify;
`

export const Links = styled.div`
  ${media.greaterThan("medium")`
    width: 350px;
  `}

  display: flex;
  flex-direction: column;
`

export const Social = styled.div`
  margin-top: 35px;

  ${customMedia.greaterThan("desktop")`
      align-self: flex-end;
  `};
`

export const Media = styled.a`
  margin-right: 35px;

  &:last-child {
    margin-right: 0px;
  }
`

export const Pages = styled.div`
  margin-top: 35px;
  display: flex;
  justify-content: space-between;
`

export const Page = styled(Link)`
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.colors.greenDarker};
`

export const HR = styled.div`
  margin-top: 35px;
  margin-bottom: 35px;
  border-bottom: 1px solid #8ede63;
`

export const Legal = styled.p`
  margin-bottom: 15px;
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.colors.charcoal};
  opacity: 0.6;
  text-align: justify;
`
