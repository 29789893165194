import styled, { keyframes } from "styled-components"
import media from "styled-media-query"
import Img from "gatsby-image"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: start;
  overflow-x: hidden;
  width: 100%;
`

export const DesktopContainer = styled.div`
  ${media.lessThan("medium")`
  display: none;
  `}
`

export const MobileContainer = styled.div`
  ${media.greaterThan("medium")`
  display: none;
  `}
`
export const Section = styled.section`
  padding-bottom: 100px;
  align-content: 'space-between';
  display: 'flex';

  ${media.greaterThan("medium")`
    padding-bottom: 210px;
  `}
`

export const Title = styled.h2`
  margin: 85px 0 30px 10%;
  font-size: 36px;
  font-weight: 800;
  line-height: 1.28;
  width: 100%;
  left: 0;
  padding-left: 10%; 
  color: ${props => props.theme.colors.greenDarker};
`

const slideToLeft = keyframes`
  100% { 
    transform: translateX(-45%);  
  }
`

export const Steps = styled.div`
  margin-top: 75px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  ${media.greaterThan("medium")`
    flex-direction: row;
    animation-name: ${slideToLeft};
    animation: ${slideToLeft} 10s linear infinite;
    animation-fill-mode: both;
    animation-direction: alternate;

    :hover {
      animation-play-state: paused;
    }
  `}
`

export const Step = styled.div`
  display: flex;
  border-radius: 10px;
  background-color: ${props => props.theme.colors.greenLighter};

  h2 {
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.6px;
    color: ${props => props.theme.colors.greenDarker};
  }

  ${media.greaterThan("medium")`
    flex-direction: column;
    width: 257px;
  `}
`

export const Name = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const Description = styled.div`
  display: flex;
  flex-direction: column;
  padding: 35px 20px;
  border-radius: 0 0 20px 20px;
  background-color: ${props => props.theme.colors.greenLighter};
  height: 185px;
`

export const StepTitle = styled.p`
  z-index: 99999;
  margin-top: -35px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 10px;
  width: 132px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.6px;
  color: ${props => props.theme.colors.greenDarker};

  ${media.greaterThan("medium")`
    display: none;
  `}
`

export const StepImageDesktop = styled(Img)`
  width: 100%;
  height: auto;
  border-radius: 20px 20px 0 0;

  ${media.greaterThan("medium")`
    width: 257px;
    height: 163px;
  `}

  ${media.lessThan("medium")`
    display: none;
  `}
`
export const PersonalizedStepImageDesktop = styled.img`
  width: 100%;
  height: auto;
  border-radius: 20px 20px 0 0;
  object-fit: cover;

  ${media.greaterThan("medium")`
    width: 257px;
    height: 163px;
  `}

  ${media.lessThan("medium")`
    display: none;
  `}
`

export const StepImageMobile = styled(Img)`
  margin-top: 5px;
  height: 132px;
  border-radius: 20px 20px 0 0;

  ${media.greaterThan("medium")`
    display: none;
  `}
`
export const PersonalizedStepImageMobile = styled.img`
  margin-top: 5px;
  height: 132px;
  border-radius: 20px 20px 0 0;
  object-fit: cover;

  ${media.greaterThan("medium")`
    display: none;
  `}
`

export const StepSubTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  line-height: 2.08;
  letter-spacing: 0.57px;
  color: #ffb504;
`

export const StepTitleDesktop = styled.h2`
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 0.6px;
  color: rgb(19, 84, 52);

  ${media.lessThan("medium")`
    display: none;
  `}
`

export const StepDescription = styled.div`
  margin-right: 40px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.charcoal};

  strong {
    font-weight: bold;
  }
`

export const Arrow = styled.img`
  src: url(${props => props.src});

  ${media.greaterThan("medium")`
    transform: rotate(270deg);
  `}
`
