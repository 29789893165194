import styled from "styled-components"
import { Container as GridContainer } from "styled-bootstrap-grid"
import media from "styled-media-query"

export const Container = styled(GridContainer)`
  background-color: ${props => props.theme.colors.greenLighter};

  ${media.greaterThan("medium")`
    display: none;
  `}
`

export const Section = styled.section`
  padding-top: 30px;
  padding-bottom: 60px;
`

export const Title = styled.p`
  font-size: 12px;
  font-weight: bold;
  line-height: 25px;
  letter-spacing: 0.6px;
  color: #ffb504;
  text-transform: uppercase;
`

export const H2 = styled.h2`
  margin-top: 8px;
  margin-bottom: 28px;
  line-height: 46px;
  font-size: ${props => props.theme.sizes.h2};
  font-weight: 800;
  color: ${props => props.theme.colors.greenDarker};
`

export const Observations = styled.div`
  opacity: 0.7;
  font-size: 8px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.4px;
  text-align: center;
  margin-top: 16px;
  color: ${props => props.theme.colors.charcoal};
`
