import styled from "styled-components"

export const Container = styled.div`
  height: 39px;
  width: 229px;
  background-color: #FE5D26;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 12px;
  padding-right: 20px;
  box-shadow: -4px 4px 4px rgba(0, 0, 0, 0.4);
  transform: rotate(-90deg);
  :hover {
      background-color: #FE5D26;
      opacity: 0.5;
      cursor: pointer;
    }
`

export const Text = styled.p`
  font-size: 0.875rem;
  font-weight: 700;
  color: #FFFFFF;
  margin-left: 10px;
`
