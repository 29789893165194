import styled from "styled-components"
import { Container as GridContainer } from "styled-bootstrap-grid"
import media from "styled-media-query"
import Img from "gatsby-image"
import { generateMedia } from "styled-media-query"

const customMedia = generateMedia({
  desktop: "78em",
  tablet: "60em",
  mobile: "46em",
})

export const Container = styled(GridContainer)`
  display: flex;
  justify-content: center;
  padding-right: 0px;
  padding-left: 0px;
  margin-top: 80px;

  ${media.greaterThan("medium")`
    margin-top: 80px;
    background-image: url(${props => props.img});
    background-repeat: no-repeat;
    background-size: 155px 190px;
    background-position: left;
  `};
`

export const Section = styled.section`
  ${customMedia.greaterThan("desktop")`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `};
`

export const Title = styled.p`
  font-size: 12px;
  font-weight: bold;
  line-height: 25px;
  letter-spacing: 0.6px;
  color: #ffb504;
  text-transform: uppercase;
`

export const SubTitle = styled.h2`
  margin-right: 25px;
  margin-top: 8px;
  margin-bottom: 28px;
  line-height: 46px;
  font-size: ${props => props.theme.sizes.h2};
  font-weight: 800;
  color: ${props => props.theme.colors.charcoal};

  ${media.greaterThan("medium")`
    color: ${props => props.theme.colors.greenDarker};
  `}
`

export const Description = styled.p`
  margin-right: 35px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.2px;
  color: ${props => props.theme.colors.charcoal};

  ${media.greaterThan("medium")`
    width: 287px;
  `}

  span {
    margin-left: 5px;
    font-weight: bold;
  }
`

export const Rates = styled.div`
  margin-top: 60px;
  display: flex;
  justify-content: center;
`

export const Individual = styled.div`
  width: 155px;
  margin-top: 30px;
  border-radius: 10px 0px 0px 10px;
  border: solid 1.4px rgba(18, 83, 50, 0.16);
  display: flex;
  flex-direction: column;

  ${media.greaterThan("medium")`
    margin-left: 13px;
    width: 246px;
    height: 330px;
  `}

  h2 {
    margin-top: 24px;
    margin-bottom: 16px;
    margin-left: 16px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.6px;
    color: ${props => props.theme.colors.charcoal};

    ${media.greaterThan("medium")`
      margin-top: 50px;
      margin-left: 35px;
      font-size: 28px;
      font-weight: 800;
      line-height: 1.36;
    `}
  }

  ul {
    margin-left: 16px;
    margin-bottom: 20px;

    ${media.greaterThan("medium")`
      margin-left: 36px;
    `}
  }

  li {
    margin-bottom: 16px;

    ${media.greaterThan("medium")`
      font-size: 18px;
      font-weight: 500;
      line-height: 1.33;
      letter-spacing: 0.6px;
    `}

    span {
      font-weight: bold;
      color: ${props => props.theme.colors.greenDarker};
    }

    strong {
      font-weight: bold;
    }

    &:before {
      content: "•";
      color: #ffb504;
      margin-right: 5px;
    }
  }
`

export const Goup = styled.div`
  border-radius: 10px;
  box-shadow: 0 14px 29px -6px rgba(34, 67, 15, 0.24);
  border: solid 1.4px rgba(18, 83, 50, 0.41);
  background-color: ${props => props.theme.colors.greenLighter};
  z-index: 999;
  width: 173px;

  ${media.greaterThan("medium")`
    width: 306px;
  `}

  h2 {
    margin-top: 24px;
    margin-bottom: 20px;
    margin-left: 22px;
    font-size: 18px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: 0.6px;
    color: ${props => props.theme.colors.greenDarker};

    ${media.greaterThan("medium")`
      margin-top: 40px;
      margin-left: 60px;
      font-size: 28px;
      font-weight: 800;
      line-height: 1.36;
      color: ${props => props.theme.colors.charcoal};
    `}
  }

  ul {
    margin-left: 16px;
    margin-right: 16px;

    ${media.greaterThan("medium")`
      margin-left: 60px;
    `}
  }

  li {
    margin-bottom: 16px;

    ${media.greaterThan("medium")`
      font-size: 18px;
      font-weight: 500;
      line-height: 1.33;
      letter-spacing: 0.6px;
    `}

    span {
      font-weight: bold;
      color: ${props => props.theme.colors.greenDarker};
    }

    strong {
      font-weight: bold;
    }

    &:before {
      content: "•";
      color: #ffb504;
      margin-right: 5px;
    }
  }
`

export const Imagem = styled.img`
  margin-top: -25px;
  ${media.greaterThan("medium")`
    display: none;
  `}
`

export const Group = styled.div`
  margin-left: 28px;
  margin-right: 28px;

  ${media.greaterThan("medium")`
    width: 525px;
  `}
`
