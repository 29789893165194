import styled from "styled-components"
import { Container as GridContainer } from "styled-bootstrap-grid"
import media from "styled-media-query"
import { generateMedia } from "styled-media-query"
import AnchorLink from "react-anchor-link-smooth-scroll"

const customMedia = generateMedia({
  desktop: "78em",
  tablet: "60em",
  mobile: "46em",
})

export const Container = styled.div`
  width: 100%;
  background-color: #125332;
  margin-top: 100px;
`

export const Section = styled(GridContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative; 
  
  ${media.greaterThan("large")`
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
  `}

  img{
    height: 452px;
    width: 100%;
    object-fit: contain;
    position: absolute;
    top: -200px;
    
    ${media.greaterThan("large")`
      height: 150%;
      width: 658px;
      left: -70px;
      top: -150px;
      position: absolute;
    `}
  }
  
  .content{
    width: 100%;
    margin-top: 280px;
    
    ${media.greaterThan("medium")`
      width: 520px;
    `}
    
    ${media.greaterThan("large")`
      flex-direction: row;
      margin-top: 75px;
    `}
    
    h1{
      font-size: 36px;
      font-weight: 800;
      line-height: 1.28;
      margin-top: 35px;
      color: ${props => props.theme.colors.offWhite};      
    }
    
    & > p{
      margin-top: 12px;
      font-size: 16px;
      line-height: 22px;
      letter-spacing: 0.2px;
      color: ${props => props.theme.colors.offWhite};   
    }
    
  }
`

export const Button = styled.div`
  display: flex;
  border-radius: 10px;
  width: 400px;
  height: 48px;
  background-color: ${props => props.theme.colors.mango};
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 95px;

  :hover {
    background-color: ${props => props.theme.colors.mango};
    opacity: 0.5;
    cursor: pointer;
  }

  h3 {
    display: block;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: ${props => props.theme.colors.charcoal};
  }
`
