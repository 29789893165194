import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"

import ArrowSVG from "../../../images/arrow.svg"

import {
  Container,
  Title,
  Steps,
  Step,
  Name,
  Description,
  StepTitle,
  StepSubTitle,
  StepDescription,
  Arrow,
  StepImageMobile,
  PersonalizedStepImageMobile,
} from "./styles"

export default function Mobile({ personalizedContent }) {
  const {
    SetpOne,
    SetpTwo,
    SetpThree,
    SetpFour,
    SetpFiver,
  } = useStaticQuery(graphql`
    query {
      SetpOne: file(relativePath: { eq: "setp-one.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 132, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      SetpTwo: file(relativePath: { eq: "setp-two.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 132, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      SetpThree: file(relativePath: { eq: "setp-three.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 132, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      SetpFour: file(relativePath: { eq: "setp-four.png" }) {
        childImageSharp {
          fluid(maxWidth: 132, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      SetpFiver: file(relativePath: { eq: "setp-five.png" }) {
        childImageSharp {
          fluid(maxWidth: 132, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const styles = {
    cards: {
      width: "100%",
    },
    arrow: {
      width: "10%",
      justifyContent: "center",
      alignSelf: "center",
      display: "flex",
      minHeight: "40px",
      padding: "20px",
    },
    swiper: {
      width: "100%",
      marginBottom: 50,
      paddingTop: 50,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  }

  return (
    <Container>
      <Title>
        A Veriza <br />
        funciona assim:
      </Title>
      <Swiper
        direction={"vertical"}
        enabled={false}
        slidesPerView={"auto"}
        style={styles.swiper}
      >
        <Steps>
          <SwiperSlide style={styles.cards}>
            <Step>
              <Name>
                <StepTitle>
                  Simule <br></br>e solicite pelo celular
                </StepTitle>
                <StepImageMobile
                  fluid={SetpOne.childImageSharp.fluid}
                ></StepImageMobile>
              </Name>
              <Description>
                <StepSubTitle>PASSO 1</StepSubTitle>
                <StepDescription>
                  Simule o seu empréstimo e faça a solicitação.
                </StepDescription>
              </Description>
            </Step>
          </SwiperSlide>
          <SwiperSlide style={styles.arrow}>
            <Arrow src={ArrowSVG} />
          </SwiperSlide>
          <SwiperSlide style={styles.cards}>
            <Step>
              <Description>
                <StepSubTitle>PASSO 2</StepSubTitle>
                <StepDescription>
                  Convide 2 pessoas com <p>bom histórico de crédito</p> e que queiram te ajudar
                </StepDescription>
              </Description>
              <Name>
                <StepTitle style={{ textAlign: "right" }}>
                  Convide <br></br> seus amigos para o grupo
                </StepTitle>
                <StepImageMobile
                  fluid={SetpTwo.childImageSharp.fluid}
                ></StepImageMobile>
              </Name>
            </Step>
          </SwiperSlide>
          <SwiperSlide style={styles.arrow}>
            <Arrow src={ArrowSVG} />
          </SwiperSlide>
          <SwiperSlide style={styles.cards}>
            <Step>
              <Name>
                <StepTitle>
                  Seus <br></br> amigos se cadastram
                </StepTitle>
                <PersonalizedStepImageMobile
                  src={require(`../../../images/${personalizedContent.carrousselImg}`)}
                ></PersonalizedStepImageMobile>
              </Name>
              <Description>
                <StepSubTitle>PASSO 3</StepSubTitle>
                <StepDescription>
                  Todos os seus amigos também baixam e se cadastram no app.
                </StepDescription>
              </Description>
            </Step>
          </SwiperSlide>
          <SwiperSlide style={styles.arrow}>
            <Arrow src={ArrowSVG} />
          </SwiperSlide>
          <SwiperSlide style={styles.cards}>
            <Step>
              <Description>
                <StepSubTitle>PASSO 4</StepSubTitle>
                <StepDescription>
                  Quando todos se cadastrarem a Veriza faz uma rápida
                  entrevista.
                </StepDescription>
              </Description>
              <Name>
                <StepTitle style={{ textAlign: "right" }}>
                  Entrevistas
                </StepTitle>
                <StepImageMobile
                  fluid={SetpFour.childImageSharp.fluid}
                ></StepImageMobile>
              </Name>
            </Step>
          </SwiperSlide>
          <SwiperSlide style={styles.arrow}>
            <Arrow src={ArrowSVG} />
          </SwiperSlide>
          <SwiperSlide style={styles.cards}>
            <Step>
              <Name>
                <StepTitle>
                  Invista <br></br> em seu negócio!
                </StepTitle>
                <StepImageMobile
                  fluid={SetpThree.childImageSharp.fluid}
                ></StepImageMobile>
              </Name>
              <Description>
                <StepSubTitle>PASSO 5</StepSubTitle>
                <StepDescription>
                  Com o dinheiro na conta você já pode investir no seu negócio.
                </StepDescription>
              </Description>
            </Step>
          </SwiperSlide>
        </Steps>
      </Swiper>
    </Container>
  )
}
