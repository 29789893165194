import styled from "styled-components"
import { Container as GridContainer } from "styled-bootstrap-grid"
import { Link } from "gatsby"
import media from "styled-media-query"

export const Container = styled.div`
  margin: 100px 0px;
  display: flex;
  ${media.lessThan("medium")`
    flex-direction: column;
  `}
 
  ${media.greaterThan("medium")`
    justify-content: center;
    margin-left: 90px;
    margin-right: 90px;
  `}

  ${media.lessThan("medium")`
    flex-direction: column;
    margin-left: 40px;
  `}

  ${media.greaterThan("large")`
    margin-top: 200px;
  `}
`

export const SectionContent = styled.div`
  flex: 1;
  display: flex;

  ${media.greaterThan("medium")`
    justify-content: center;
  `}
`

export const ContactContent = styled.div`
  width: 75%;
  ${media.lessThan("medium")`
    margin-top: 80px;
  `}
`

export const CallToActionContent = styled.div`
  h1 {
    font-size: 41px;
    font-weight: 800;
    line-height: 1.36;
    color: ${props => props.theme.colors.charcoal};
  }
  width: 90%;
  display: flex;
  flex-direction: column;
`
export const PlayStoreImage = styled.img`
  width: 189px;
  height: 55px;
  src: url(${props => props.src});
  margin-top: 20px;
  :hover {
    cursor: pointer;
  }
`

export const Title = styled.h2`
  margin-bottom: 40px;
  font-size: 28px;
  font-weight: 800;
  line-height: 1.36;
  color: ${props => props.theme.colors.charcoal};

  ${media.greaterThan("medium")`
  font-size: 36px;
  font-weight: 800;
  line-height: 1.28;
  `}
`
export const Description = styled.p`
  margin-top: 24px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.charcoal};
  strong {
    font-weight: 800;
  }
`

export const Phone = styled.p`
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: 0.8px;
  color: ${props => props.theme.colors.greenDark};

  span {
    font-size: 18px;
    letter-spacing: 0.6px;
    color: ${props => props.theme.colors.greenDark};
  }
`

export const Crisp = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.charcoal};

  strong {
    font-weight: 800;
  }
`

export const FAQ = styled(Link)`
  text-decoration: none;
  display: flex;
  margin-top: 40px;
  font-size: 16px;
  font-weight: 500;
  color: ${props => props.theme.colors.greenDark};
  button {
    width: 315px;
    height: 48px;
    border-radius: 10px;
    border: solid 1px ${props => props.theme.colors.greenDarker};
    background-color: #ffffff;
    p {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
      color: ${props => props.theme.colors.greenDarker};
    }
    :hover {
      background-color: rgba(19, 84, 52, 0.1);
    }
  }
`

export const Arrow = styled.img`
  margin-left: 10px;
  src: url(${props => props.src});
`

export const Zap = styled.img`
  width: 25px;
  height: 25px;
  src: url(${props => props.src});
`
