import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import iconGroup from "../../../images/ico-duo-group.svg"
import iconBureaucracy from "../../../images/ico-duo-briefcase.svg"
import iconFees from "../../../images/ico-duo-chart.svg"

import {
  Container,
  Section,
  Imagem,
  Title,
  Description,
  Benefits,
  Benefit,
  Text,
  Content,
  ImagemDesktop,
  Icon,
} from "./styles"

const Group = ({ personalizedContent }) => {
  const { People, PeopleDesktop } = useStaticQuery(graphql`
    query {
      People: file(relativePath: { eq: "people.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 375, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      PeopleDesktop: file(relativePath: { eq: "people-desktop.png" }) {
        childImageSharp {
          fluid(maxWidth: 534, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Section>
        <img src={require(`../../../images/${personalizedContent.supportImage}`)} />
        <Content>
          <Title>Acreditamos no poder dos grupos!</Title>
          <Description>
            Quando você se junta a outras pessoas,
            <p>você consegue mais!</p>
          </Description>
          <Benefits>
            <Benefit>
              <Icon src={iconGroup} />
              <Text>Garantia coletiva</Text>
            </Benefit>
            <Benefit>
              <Icon src={iconBureaucracy} />
              <Text>Menos burocracia</Text>
            </Benefit>
            <Benefit>
              <Icon src={iconFees} />
              <Text>Taxas acessíveis</Text>
            </Benefit>
          </Benefits>
        </Content>
      </Section>
    </Container>
  )
}
export default Group
