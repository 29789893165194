import React from "react"

import LogoVeriza from "../../../images/logo-crop.png"

import {
  Container,
  Section,
  Title,
  SubTitle,
  Description,
  Rates,
  Individual,
  Goup,
  Imagem,
  Group,
} from "./styles"

export default function Compare({ personalizedContent }) {
  return (
    <Container img={LogoVeriza} fluid>
      <Section>
        <Group>
          <Title>Compare</Title>
          <SubTitle>
            Por que crédito<br></br> em grupo?
          </SubTitle>
          <Description>
            Com o crédito em grupo da Veriza é possível conseguir empréstimos com
            <span>taxas acessíveis</span> com a colaboração dos seus convidados (avalistas), que servem como garantia adicional para ajudar na sua reintegração ao mercado de crédito.
          </Description>
        </Group>
        <Rates>
          <Individual>
            <h2>
              Crédito<br></br> tradicional
            </h2>
            <ul>
              <li>Análise de risco mais rígida</li>
              <li>
                Bens pessoais como garantia
              </li>
              <li>
                Taxa de juros mais alta.
              </li>
            </ul>
          </Individual>
          <Goup>
            <h2>Crédito na Veriza</h2>
            <ul>
              <li>
                Transparência<br></br> e acessibilidade
              </li>
              <li>
                <strong>O seu grupo</strong>
                <br></br> como garantia
              </li>
              <li>
                Todos conseguem o valor contratado
              </li>
              <li>
                Taxa de juros mais baixa.
              </li>
            </ul>
          </Goup>
        </Rates>
        {personalizedContent.simulatorImage && <Imagem src={require(`../../../images/${personalizedContent.simulatorImage}`)} />}

      </Section>
    </Container>
  )
}
