import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import {
  Container,
  Link,
  Title,
  StepImage,
  MediaList
} from "./styles"

export default function Media() {
  const {
    Captable,
    StartSe,
    Finsiders,
    Startupi,
    ValorEconomico
  } = useStaticQuery(graphql`
    query {
      Captable: file(relativePath: { eq: "captable.jpg" }) {
        childImageSharp {
          fixed(width: 175, height: 31, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      StartSe: file(relativePath: { eq: "startse.jpg" }) {
        childImageSharp {
          fixed(width: 175, height: 25, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      Finsiders: file(relativePath: { eq: "finsiders.jpg" }) {
        childImageSharp {
          fixed(width: 175, height: 76, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      Startupi: file(relativePath: { eq: "startupi.jpg" }) {
        childImageSharp {
          fixed(width: 175, height: 43, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      ValorEconomico: file(relativePath: { eq: "valoreconomico.jpg" }) {
        childImageSharp {
          fixed(width: 175, height: 59, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <>
      <Title>Veriza na mídia:</Title>
      <Container>
        <MediaList>
          <Link href="https://app.startse.com/artigos/veriza-a-fintech-que-desbrava-mercado-inedito" target="_blank">
            <StepImage fixed={StartSe.childImageSharp.fixed} />
          </Link>
          <Link href="https://captable.com.br/projects/veriza" target="_blank">
            <StepImage fixed={Captable.childImageSharp.fixed} />
          </Link>
          <Link href="https://finsiders.com.br/2021/06/14/microcredito-em-grupo-e-a-aposta-da-veriza-que-vai-captar-r-15-milhao/" target="_blank">
            <StepImage fixed={Finsiders.childImageSharp.fixed} />
          </Link>
          <Link href="https://startupi.com.br/2021/06/fintech-que-oferece-microcredito-para-microempreendedores-recebe-aporte-de-r-15-milhao/" target="_blank">
            <StepImage fixed={Startupi.childImageSharp.fixed} />
          </Link>
          <Link href="https://pipelinevalor.globo.com/startups/noticia/com-fintech-veriza-captable-poe-mais-uma-rodada-relampago-na-conta.ghtml" target="_blank">
            <StepImage fixed={ValorEconomico.childImageSharp.fixed} />
          </Link>
        </MediaList>
      </Container>
    </>
  )
}
