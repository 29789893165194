import styled from "styled-components"
import media from "styled-media-query"
import Img from "gatsby-image/withIEPolyfill"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 150px;
  overflow-x: auto;
  ${media.lessThan("medium")`
      align-items: start;
      ::-webkit-scrollbar {
          display: none;
      }
  `}
`

export const Link = styled.a`
  margin-right: 35px;

  &:last-child {
    margin-right: 0px;
  }
`

export const Title = styled.h2`
  margin-top: 85px;
  font-size: 36px;
  font-weight: 800;
  text-align: center;
  line-height: 1.28;
  color: ${props => props.theme.colors.charcoal};
`

export const MediaList = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 50px 0px 50px;
  
`

export const StepImage = styled(Img)`
  width: 175px;
  margin: 0px 20px;
`