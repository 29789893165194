import styled from "styled-components"
import { Container as GridContainer } from "styled-bootstrap-grid"
import media from "styled-media-query"
import { generateMedia } from "styled-media-query"
import Img from "gatsby-image"

const customMedia = generateMedia({
  desktop: "78em",
  tablet: "60em",
  mobile: "46em",
})

export const How = styled.div`
  ${media.greaterThan("medium")`
    width: 350px;
  `}
`

export const Container = styled(GridContainer)`
  display: flex;
  justify-content: center;
  margin-top: 50px;
  padding-left: 0px;
  padding-right: 0px;
  background: rgb(252, 254, 236);
  background: linear-gradient(
    0deg,
    rgba(252, 254, 236, 1) 20%,
    rgba(255, 255, 255, 1) 100%
    );
  ${media.lessThan("medium")`
    padding-bottom: 250px;
  `}
`

export const Section = styled.section`
  padding-left: 0px;
  padding-right: 0px;

  ${media.greaterThan("medium")`
    display: flex;
  `}
`

export const Title = styled.p`
  width: 300px;
  margin-left: 15px;
  font-size: 36px;
  font-weight: 800;
  line-height: 1.28;
  color: ${props => props.theme.colors.greenDarker};

  span {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 19%,
      rgba(245, 255, 182, 1) 19%,
      rgba(245, 255, 182, 1) 88%,
      rgba(255, 255, 255, 1) 88%
    );
  }
`

export const Description = styled.p`
  margin-top: 30px;
  margin-left: 15px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  color: ${props => props.theme.colors.charcoal};

  span {
    font-weight: 600;
  }

  strong {
    font-weight: bold;
  }
`

export const Chats = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
  margin-left: 15px;
  margin-right: 15px;
`

export const Chat = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100px;
`

export const Icon = styled.img`
  src: url(${props => props.src});
`

export const Text = styled.h3`
  margin-top: 15px;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: ${props => props.theme.colors.charcoal};
`

export const Mocks = styled.div`
  margin-left: 90px;
  display: flex;
  align-items: center;

  ${media.lessThan("medium")`
    display: none;
  `}
`

export const Phone = styled(Img)`
  margin-right: 15px;
  width: 260px;
  height: 520px;
`

export const Others = styled(Img)`
  width: 217px;
  height: 388px;

  ${customMedia.lessThan("desktop")`
     display: none;
  `};
`

export const Optional = styled(Img)`
  min-width: 55px;
  height: 388px;
  display: block;

  ${customMedia.lessThan("desktop")`
     display: none;
  `};
`
