import React, { useCallback, useRef } from "react"
import { useStaticQuery, graphql, navigate } from "gatsby"
import {
  Container,
  Section,
  Background,
  Home,
  Logo,
  TitleHome,
  DescriptionHome,
  Mocks,
  Image,
  Simulator,
  Menu,
  Links,
  FAQ,
  Title,
  H2,
  Observations,
  LinksContainer,
  ContainerSimulator,
  Button,
  IndividualCreditContainer
} from "./styles"


import SimulatorComponent from "../../../components/simulator"
import IndividualCreditButton from "../../../components/IndividualCreditButton"
import Alert from "../../alert"

const PitchAndSimulator = ({ trackingUtm, personalizedContent }) => {
  const simulatorRef = useRef(null);


  const { Veriza, Mock, Camila } = useStaticQuery(graphql`
    query {
      Veriza: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 220, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      Mock: file(relativePath: { eq: "mock-desktop.png" }) {
        childImageSharp {
          fluid(maxWidth: 676, quality: 95) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      Camila: file(relativePath: { eq: "camila-home.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 676, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  const scrollToSimulator = useCallback(() => {
    simulatorRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [simulatorRef]);

  return (
    <Container fluid id="pitch">
      <Section style={{ flexDirection: 'column', height: 820, justifyContent: 'flex-start' }}>
        <Background src={require(`../../../images/${personalizedContent.backgroundImg}`)} />
        <Alert />
        <Section>
          <div style={{ width: 500 }}>
            <Logo fluid={Veriza.childImageSharp.fluid} />
          </div>
          <Menu>
            <LinksContainer>
              <Links >
                <FAQ to="/" style={{ color: personalizedContent.navbarTextColor }}>Início</FAQ>
              </Links>
              <Links>
                <FAQ to="/faq" style={{ color: personalizedContent.navbarTextColor }}>Perguntas frequentes</FAQ>
              </Links>
              <Links>
                <FAQ to="http://blog.veriza.com.br/" style={{ color: personalizedContent.navbarTextColor }}>Blog Veriza</FAQ>
              </Links>
            </LinksContainer>
          </Menu>
        </Section>
        {/* <IndividualCreditContainer>
          <IndividualCreditButton />
        </IndividualCreditContainer> */}
        <Section>
          <ContainerSimulator>
            <TitleHome style={{ "color": personalizedContent.titleColor }}>
              {personalizedContent.titleText}
            </TitleHome>
            <DescriptionHome style={{ "color": personalizedContent.textColor }}>
              {personalizedContent.supportText}
            </DescriptionHome>


            <Button onClick={scrollToSimulator}>
              <h3>Simular agora</h3>
            </Button>
          </ContainerSimulator>
        </Section>
      </Section>

      <Section>
        <Home>
          <Mocks>
            <img src={require(`../../../images/${personalizedContent.arrowImage}`)} />
          </Mocks>
        </Home>
        <Simulator id="simulador" ref={simulatorRef}>
          <Title>Simule e solicite</Title>
          <H2>De quanto você precisa?</H2>
          <SimulatorComponent trackingUtm={trackingUtm} />
          <Observations>
            *Valores das parcelas são aproximados, outras taxas podem ser aplicadas.
            Verificar condições de pagamento e acordos contratuais.
          </Observations>
        </Simulator>
      </Section>
    </Container>
  )
}

export default PitchAndSimulator
