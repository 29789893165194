import React from "react"

import { Container, Section, Title, H2, Observations } from "./styles"

import SimulatorComponent from "../../../components/simulator"

const Simulator = ({ trackingUtm, simulatorRef }) => {
  return (
    <Container fluid id="simulador-mobile">
      <Section>
        <Title>Simular Agora</Title>
        <H2 ref={simulatorRef}>De quanto você precisa?</H2>
        <SimulatorComponent trackingUtm={trackingUtm} />
        <Observations>
          *Valores das parcelas são aproximados, outras taxas podem ser aplicadas.
          Verificar condições de pagamento e acordos contratuais.
        </Observations>
      </Section>
    </Container>
  )
}

export default Simulator
