import React from "react"

import Veriza from "../../../images/logo.png"
import Linkedin from "../../../images/linkedin.svg"
import Instagram from "../../../images/instagram.svg"
import Facebook from "../../../images/facebook.svg"
import Youtube from "../../../images/youtube.svg"

import {
  Container,
  Section,
  Header,
  Logo,
  Description,
  Links,
  Social,
  Media,
  Pages,
  Page,
  HR,
  Legal,
} from "./styles"

export default function Footer() {
  return (
    <Container fluid>
      <Section>
        <Header>
          <Logo src={Veriza} />
          <Description>
            Somos uma empresa voltada para micro e pequenos empreendedores que têm necessidade de crédito simples e rápido. Vamos auxiliar as pessoas a atingirem seus objetivos utilizando novas formas de concessão de crédito. A educação financeira é um pilar do nosso projeto. Para nós o crédito é uma solução e não um problema, uma ferramenta de inclusão social.
          </Description>
          <Links>
            <Social>
              <Media href="https://www.linkedin.com/company/veriza-correspondente-banc%C3%A1rio-ltda/about/" target="_blank">
                <img src={Linkedin} />
              </Media>
              <Media href="https://www.instagram.com/verizapravoce/" target="_blank">
                <img src={Instagram} />
              </Media>
              <Media href="https://www.facebook.com/verizapravoce/?ref=br_rs" target="_blank">
                <img src={Facebook} />
              </Media>
              <Media href="https://www.youtube.com/c/Verizapravoce" target="_blank">
                <img src={Youtube} />
              </Media>
            </Social>
            <Pages>
              <Page to="/privacy">Política de privacidade</Page>
              <Page to="/terms">Termos de uso</Page>
            </Pages>
          </Links>
        </Header>
        <HR />
        <Legal>
        Veriza Correspondente Bancário LTDA, CNPJ 36.026.347/0001-50, com sede no SAUS Quadra 04, Bloco A, Sala 701, parte 0014, Edifício Victoria Office Tower, Asa Sul, Brasília-DF, CEP 70.070-040. A Veriza não é uma instituição financeira, atuamos como um correspondente bancário da CDC Sociedade de Crédito ao Microempreendedor e a Empresas de Pequeno Porte, sociedade empresária limitada com sede na Avenida Raja Gabaglia, nº 4.000, Sala 419, Estoril, Belo Horizonte, Minas Gerais, CEP: 30494-310, inscrita no CNPJ(MF), sob nº 18.394.228/0001-79 nos termos da Resolução no 3.954, de 24 de fevereiro de 2011. O atraso no pagamento das prestações do seu empréstimo pessoal pode ter como consequência a inclusão do seu nome nos cadastros de inadimplentes dos órgãos de proteção ao crédito, o protesto de títulos e, ainda, o ajuizamento de ações judiciais de cobrança, dentre outras. Para evitar maiores problemas e restrições ao seu crédito, mantenha sempre o pagamento de suas parcelas em dia. As taxas de juros da operação de crédito variam de 2,0% a.m. (26,82% a.a.) a 5,0% a.m. (79,58% a.a.). A Veriza tem total compromisso com simplicidade e transparência. Antes de contratar uma operação de crédito, será exibido de forma clara: a taxa de juros aplicada, tarifas cobradas, impostos (IOF) e o custo efetivo total (CET). Sabemos que não é fácil entender todos esses valores e em caso de dúvidas, estamos à disposição para esclarecê-los. Período de quitação do empréstimo de 4 a 36 meses, podendo ser antecipado a qualquer momento.
        </Legal>
      </Section>
    </Container>
  )
}
