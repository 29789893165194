import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Container, Link, Title, StepImage, MediaList, Image } from "./styles"

export default function Partners() {
  const {
    LIF,
    Cotidiano,
    Ventures
  } = useStaticQuery(graphql`
    query {
      LIF: file(relativePath: { eq: "logo-lif.png" }) {
        childImageSharp {
          fixed(width: 198, height: 50, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      Cotidiano: file(relativePath: { eq: "logo-cotidiano.png" }) {
        childImageSharp {
          fixed(width: 175, height: 50, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      Ventures: file(relativePath: { eq: "logo-ventures.png" }) {
        childImageSharp {
          fixed(width: 212, height: 50, quality: 100) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <>
      <Container>
      <Title>Nossos parceiros:</Title>
        <MediaList>
          <Link href="https://www.caf.com/pt/sobre-a-caf/" target="_blank">
            <StepImage fixed={LIF.childImageSharp.fixed} />
          </Link>
          <Link href="https://cotidiano.com.br/" target="_blank">
          <StepImage fixed={Cotidiano.childImageSharp.fixed} />
          </Link>
          <Link href="https://ventures.com.co/site/" target="_blank">
            <StepImage fixed={Ventures.childImageSharp.fixed} />
          </Link>
        </MediaList>
      </Container>
    </>
  )
}
