import styled from "styled-components"
import { Container as GridContainer } from "styled-bootstrap-grid"
import media from "styled-media-query"
import { Link } from "gatsby"
import Img from "gatsby-image"

export const Gradient = styled(GridContainer)`
  padding-left: 0px;
  padding-right: 0px;
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 5%, rgba(240,251,234,1) 35%);
  background-image: url(${props => props.src});

  ${media.greaterThan("medium")`
    display: none;
  `}
`

export const ContainerVideo = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 100px;

  ${media.greaterThan("medium")`
    display: none;
  `}
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`

export const Logo = styled(Img)`
  width: 156px;
  height: 37px;
  margin-top: 45px;
  margin-left: 30px;

  ${media.greaterThan("medium")`
    display: none;
  `}
`

export const MenuIcon = styled.img`
  src: url(${props => props.src});
  width: 26px;
  height: 26px;
  ${media.greaterThan("medium")`
    display: none;
  `}
  margin-right: 25px;
  margin-top: 40px;
`

export const Title = styled.p`
  margin-left: 30px;
  margin-top: 65px;
  font-size: 27px;
  font-weight: 800;
  line-height: 1.23;
  color: ${props => props.theme.colors.greenDarker};

  span {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 19%,
      rgba(245, 255, 182, 1) 19%,
      rgba(245, 255, 182, 1) 88%,
      rgba(255, 255, 255, 1) 88%
    );
  }
`

export const Description = styled.p`
  margin-top: 32px;
  margin-right: 35px;
  margin-left: 30px;
  padding-bottom: 45px;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${props => props.theme.colors.charcoal};
`

export const HeaderModal = styled.div`
  display: flex;
  marginTop: 35px;
  marginRight: 10px;
  justify-content: flex-end;
`

export const CloseIcon = styled.img`
  src: url(${props => props.src});
  width: 20px;
  height: 20px;
`
export const MenuContainer = styled.div`
  display:flex;
  flex-direction:column;
  justify-content:space-between;
  margin-top: 30px;
  height: 210px;
`

export const ItemMenu = styled(Link)`
  text-decoration: none;
  margin-botton: 100px;
  p {
    font-size: 24px;
    font-weight: bold;
    line-height: 46px;
    letter-spacing: 0.4px;
    color: ${props => props.theme.colors.charcoal};
  };
`

export const ModalFooter = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

`

export const SocialIcons = styled.img`
  src: url(${props => props.src});
  width: ${props => props.width};
  height: ${props => props.height};
  margin-right: 32px;
  margin-top: 250px;
`

export const Background = styled.img`
  position: absolute;
  width: 100vw;
  height: 500px;
  top: 0;
  z-index: -1;
  object-fit: cover;
  align-self: center;
  opacity: 0.55;
  ${media.greaterThan("medium")`
    display: none;
  `}
`

export const Button = styled.div`
  display: flex;
  border-radius: 10px;
  height: 48px;
  background-color: ${props => props.theme.colors.mango};
  justify-content: center;
  align-items: center;
  align-self: center;
  margin: 32px;

  :hover {
    background-color: ${props => props.theme.colors.mango};
    opacity: 0.5;
    cursor: pointer;
  }

  p {
    display: block;
    font-size: 15px;
    font-weight: bold;
    letter-spacing: 0.4px;
    color: ${props => props.theme.colors.charcoal};
  }

  ${media.greaterThan("medium")`
    display: none;
  `}
`

export const Context = styled.div`
  ${media.greaterThan("medium")`
    display: none;
  `}
`